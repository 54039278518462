<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="100px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="类型" prop="mediaType">
        <el-radio-group v-model="dataForm.mediaType" :disabled="disabled">
          <el-radio :label="0"> 图片 </el-radio>
          <el-radio :label="1"> 链接 </el-radio>
          <el-radio :label="2"> 文本 </el-radio>
          <el-radio :label="3"> 视频 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="名称" prop="title">
        <el-input
          v-model="dataForm.title"
          :disabled="disabled"
          placeholder=""
        />
      </el-form-item>
      <el-form-item label="图片链接" prop="imageUrl">
        <el-img v-model="dataForm.imageUrl" :disabled="disabled" />
      </el-form-item>
      <el-form-item
        v-if="dataForm.mediaType === 1"
        label="小程序页面"
        prop="link"
      >
        <el-input
          v-model="dataForm.link"
          :disabled="disabled"
          placeholder="小程序页面"
        />
      </el-form-item>
      <el-form-item
        v-if="dataForm.mediaType === 3"
        label="视频链接"
        prop="videoUrl"
      >
        <!-- action必选参数, 上传的地址 -->
        <el-upload
          class="avatar-uploader el-upload-text"
          :action="url"
          :show-file-list="false"
          :on-success="handleVideoSuccess"
          :before-upload="beforeUploadVideo"
          :on-progress="uploadVideoProcess"
        >
          <video
            v-if="dataForm.videoUrl && !videoFlag"
            :src="dataForm.videoUrl"
            style="width: 400px; height: 300px"
            controls="controls"
          >
            您的浏览器不支持视频播放
          </video>
          <i
            v-else-if="!dataForm.videoUrl && !videoFlag"
            class="el-icon-plus avatar-uploader-icon"
          />
          <el-progress
            v-if="videoFlag"
            type="circle"
            :percentage="videoUploadPercent"
            style="margin-top: 30px"
          />
        </el-upload>
        <p class="text">请保证视频格式正确，且不超过10M</p>
      </el-form-item>
      <el-form-item label="文本内容" prop="content">
        <ueditor
          v-model="dataForm.content"
          :disabled="disabled"
          placeholder="文本内容"
        />
      </el-form-item>
      <el-form-item label="结束时间" prop="endTime">
        <el-date-picker
          v-model="dataForm.endTime"
          type="datetime"
          :picker-options="datePicker"
          :disabled="disabled"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="结束时间"
        />
      </el-form-item>
      <el-form-item label="状态" prop="enabled">
        <el-radio-group v-model="dataForm.enabled" :disabled="disabled">
          <el-radio :label="0"> 禁用 </el-radio>
          <el-radio :label="1"> 正常 </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { keys, pick } from 'lodash';

export default {
  data() {
    return {
      url: '',
      disabled: false,
      visible: false,
      videoFlag: false,
      videoUploadPercent: 0,
      dataForm: {
        id: '',
        mediaType: 0,
        title: '',
        imageUrl: '',
        link: '',
        videoUrl: '',
        content: '',
        endTime: '',
        enabled: 0,
      },
      dataRule: {
        title: [
          {
            required: true,
            message: '标题不能为空',
            trigger: 'blur',
          },
        ],
      },
      datePicker: this.picker(),
    };
  },
  methods: {
    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/x-flv',
          'video/x-msvideo',
          'video/x-ms-wmv',
          'video/rmvb',
        ].indexOf(file.type) === -1
      ) {
        this.$message.error('请上传正确的视频格式');
        return false;
      }
      if (!isLt10M) {
        this.$message.error('上传视频大小不能超过10MB哦!');
        return false;
      }
    },
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percentage.toFixed(0));
    },
    // 获取上传地址
    handleVideoSuccess(response, file) {
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      if (response && response.code === 0) {
        this.dataForm.videoUrl = response.url;
      } else {
        this.$message.error('视频上传失败，请重新上传！');
      }
    },
    picker() {
      return {
        // 可选时间大于等于当前时间
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      };
    },
    init(id, disabled) {
      this.url =
        this.$http.BASE_URL +
        `/sys/oss/upload?token=${this.$cookie.get('token')}`;
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/mall/banner/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.banner, keys(this.dataForm));
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/mall/banner/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
